.paginationBttns {
  margin-top: 20px;
    width: 80%;
    height: 20px;
    list-style: none;
    display: flex;
    justify-content: center;
  }
  
  .paginationBttns a {
    padding: 5px;
    margin: 4px;
    border-radius: 5px;
    border: 1px solid #2b2eff;
    color: #2b2eff;
    cursor: pointer;
  }
  
  .paginationBttns a:hover {
    color: white;
    background-color: #2b2eff;
  }
  
  .paginationActive a {
    color: white;
    background-color: #2b2eff;
  }
  
  .paginationDisabled a {
    color: grey;
    background-color: grey;
  }