
body{
    text-align: center;
    padding: auto;  
}

.error-message{
    font-size: 1.2rem;
    color: red;
}

.ql-editor {
    overflow: hidden;
    overflow-y: auto;
    overflow-x: auto;
  }

.sticky{
    position: -webkit-sticky; /* Safari */
    position: sticky;
    top: 0; 
    
  }

a{
  text-decoration: none;
}

.footer-link a {
    text-decoration: none;
    color: #fff;
}

.footer-link a:hover {
    text-decoration: none;
    color: red;
}
.title-article{
    font-size: 16px;
    font-weight: bold;
    color: #CC3300;
}
